<template>
<div>
    <b-row>

        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-file-alt fa-md"></i> <span class="h5"> Gestión de documentos </span>

                </CCardHeader>

                <CCardBody>
                    <div>
                        <span class="text-muted h6">A CONTINUACIÓN SE MUESTRA LA DOCUMENTACIÓN CARGADA AL SISTEMA</span>
                        <b-row class="mt-3">
                            <b-col md="12" class="my-2">
                                <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-dark">
                                    <b-tab v-if="checkPermissions('001-DOC-DES-INT','r') == 1" title="Internos" active>
                                        <b-row>
                                            <b-col v-if="checkPermissions('001-DOC-DES-INT','c') == 1" md="12" class="text-right">
                                                <b-button variant="dark" class="mt-2" @click="modalRegistrarDocumento = true; datosDocumento.idAmbitoDocumento = 1 ">
                                                    <i class="fas fa-plus fa-sm"></i> Nuevo documento
                                                </b-button>
                                            </b-col>
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina1" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7">

                                            </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter1" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaDocumentos.filter(x => x.idAmbitoDocumento == 1)" :fields="campoDocumentosInternos" bordered :per-page="porPagina1" :current-page="currentPage1" @filtered="onFiltered1" :filter="filter1" :filter-included-fields="filterOn1" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template #cell(opciones)="param">
                                                        <b-button @click="descargarDocumento(param.item.urlArchivo)" class="mr-1 mb-1" variant="blue" size="sm" v-c-tooltip="'Descargar'">
                                                            <i class="fas fa-download"></i>
                                                            <!-- <i class="fas fa-download fa-md"></i>  -->
                                                        </b-button>
                                                        <b-button v-if="checkPermissions('001-DOC-DES-INT','u') == 1" @click="abrirModalActualizarDocumento(param, 1)" class="mr-1 mb-1" variant="dark" size="sm" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search"></i>
                                                            <!-- <i class="fas fa-search fa-md"></i> -->
                                                        </b-button>
                                                        <b-button v-if="checkPermissions('001-DOC-DES-INT','d') == 1" @click="eliminarDocumento(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            <!-- <i class="fas fa-trash fa-md"></i> -->
                                                        </b-button>
                                                    </template>
                                                    <!-- <template #cell(nombre)="param">
                                                        {{param.item.nombre | truncate(25)}}
                                                    </template> -->
                                                    <template #cell(nombreProceso)="param">
                                                        {{param.item.nombreProceso | truncate(25)}}
                                                    </template>
                                                    <template #cell(nombreTipoDocumentacion)="param">
                                                        {{param.item.nombreTipoDocumentacion | truncate(25)}}
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr>
                                                <b-pagination v-model="currentPage1" :total-rows="totalRows1" :per-page="porPagina1" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                    <b-tab v-if="checkPermissions('001-DOC-DES-EXT','r') == 1" title="Externos">
                                        <b-row>
                                            <b-col md="12" v-if="checkPermissions('001-DOC-DES-EXT','c') == 1" class="text-right">
                                                <b-button variant="dark" class="mt-2" @click="modalRegistrarDocumento = true; datosDocumento.idAmbitoDocumento = 2">
                                                    <i class="fas fa-plus fa-sm"></i> Nuevo documento
                                                </b-button>
                                            </b-col>
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina2" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7">

                                            </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter2" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaDocumentos.filter(x => x.idAmbitoDocumento == 2)" :fields="campoDocumentosExternos" bordered :per-page="porPagina2" :current-page="currentPage2" @filtered="onFiltered2" :filter="filter2" :filter-included-fields="filterOn2" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template #cell(opciones)="param">
                                                        <b-button @click="descargarDocumento(param.item.urlArchivo)" class="mr-1 mb-1" variant="blue" size="sm" v-c-tooltip="'Descargar'">
                                                            <i class="fas fa-download"></i>
                                                        </b-button>
                                                        <b-button v-if="checkPermissions('001-DOC-DES-EXT','u') == 1" @click="abrirModalActualizarDocumento(param, 2)" class="mr-1 mb-1" variant="dark" size="sm" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search"></i>
                                                        </b-button>
                                                        <b-button v-if="checkPermissions('001-DOC-DES-EXT','d') == 1" @click="eliminarDocumento(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                    <template #cell(tipoUbicacion)="param">
                                                        <span v-if="param.item.tipoUbicacion == 1">Virtual</span>
                                                        <span v-else-if="param.item.tipoUbicacion == 2">Fisica</span>
                                                    </template>
                                                    <template #cell(nombreProceso)="param">
                                                        {{param.item.nombreProceso | truncate(25)}}
                                                    </template>
                                                    <template #cell(nombreTipoDocumentacion)="param">
                                                        {{param.item.nombreTipoDocumentacion | truncate(25)}}
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr>
                                                <b-pagination v-model="currentPage2" :total-rows="totalRows2" :per-page="porPagina2" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                    <b-tab title="Registro" v-if="checkPermissions('001-DOC-DES-REG','r') == 1">
                                        <b-row>
                                            <b-col md="12" v-if="checkPermissions('001-DOC-DES-REG','c') == 1" class="text-right">
                                                <b-button variant="dark" class="mt-2" @click="modalRegistrarDocumento = true; datosDocumento.idAmbitoDocumento = 3">
                                                    <i class="fas fa-plus fa-sm"></i> Nuevo documento
                                                </b-button>
                                            </b-col>
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina3" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7">

                                            </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter3" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaDocumentos.filter(x => x.idAmbitoDocumento == 3)" :fields="campoDocumentosRegistros" bordered :per-page="porPagina3" :current-page="currentPage3" @filtered="onFiltered3" :filter="filter3" :filter-included-fields="filterOn3" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template #cell(opciones)="param">
                                                        <b-button @click="descargarDocumento(param.item.urlArchivo)" class="mr-1 mb-1" variant="blue" size="sm" v-c-tooltip="'Descargar'">
                                                            <i class="fas fa-download"></i>
                                                        </b-button>
                                                        <b-button v-if="checkPermissions('001-DOC-DES-REG','u') == 1" @click="abrirModalActualizarDocumento(param, 3)" class="mr-1 mb-1" variant="dark" size="sm" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search"></i>
                                                        </b-button>
                                                        <b-button v-if="checkPermissions('001-DOC-DES-REG','d') == 1" @click="eliminarDocumento(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                    <template #cell(tipoUbicacion)="param">
                                                        <span v-if="param.item.tipoUbicacion == 1">Virtual</span>
                                                        <span v-else-if="param.item.tipoUbicacion == 2">Fisica</span>
                                                    </template>
                                                    <template #cell(nombreProceso)="param">
                                                        {{param.item.nombreProceso | truncate(25)}}
                                                    </template>
                                                    <template #cell(nombreTipoDocumentacion)="param">
                                                        {{param.item.nombreTipoDocumentacion | truncate(25)}}
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr>
                                                <b-pagination v-model="currentPage3" :total-rows="totalRows3" :per-page="porPagina3" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                    <b-tab title="Distribución" v-if="checkPermissions('001-DOC-DES-DIS','r') == 1">
                                        <b-row>
                                            <b-col v-if="checkPermissions('001-DOC-DES-DIS','c') == 1" md="12" class="text-right">
                                                <b-button variant="dark" class="mt-2" @click="modalRegistrarDocumento = true; datosDocumento.idAmbitoDocumento = 4">
                                                    <i class="fas fa-plus fa-sm"></i> Nuevo documento
                                                </b-button>
                                            </b-col>
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina4" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7">

                                            </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter4" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaDocumentos.filter(x => x.idAmbitoDocumento == 4)" :fields="campoDocumentosDistribucion" bordered :per-page="porPagina4" :current-page="currentPage4" @filtered="onFiltered4" :filter="filter4" :filter-included-fields="filterOn4" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template #cell(opciones)="param">
                                                        <b-button @click="descargarDocumento(param.item.urlArchivo)" class="mr-1 mb-1" variant="blue" size="sm" v-c-tooltip="'Descargar'">
                                                            <i class="fas fa-download"></i>
                                                        </b-button>
                                                        <b-button v-if="checkPermissions('001-DOC-DES-DIS','u') == 1" md="12" @click="abrirModalActualizarDocumento(param, 4)" class="mr-1 mb-1" variant="dark" size="sm" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search"></i>
                                                        </b-button>
                                                        <b-button v-if="checkPermissions('001-DOC-DES-DIS','d') == 1" md="12" @click="eliminarDocumento(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                    <template #cell(tipoUbicacion)="param">
                                                        <span v-if="param.item.tipoUbicacion == 1">Virtual</span>
                                                        <span v-else-if="param.item.tipoUbicacion == 2">Fisica</span>
                                                    </template>
                                                    <template #cell(copia)="param">
                                                        <span v-if="param.item.copia == 1">Original</span>
                                                        <span v-else-if="param.item.copia == 2">Controlado</span>
                                                        <span v-else-if="param.item.copia == 3">No controlado</span>
                                                    </template>
                                                    <template #cell(nombreProceso)="param">
                                                        {{param.item.nombreProceso | truncate(25)}}
                                                    </template>
                                                    <template #cell(nombreTipoDocumentacion)="param">
                                                        {{param.item.nombreTipoDocumentacion | truncate(25)}}
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr>
                                                <b-pagination v-model="currentPage4" :total-rows="totalRows4" :per-page="porPagina4" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                    <b-tab title="Dirección" v-if="checkPermissions('001-DOC-DES-DIR','r') == 1" md="12">
                                        <b-row>
                                            <b-col v-if="checkPermissions('001-DOC-DES-DIR','c') == 1" md="12" class="text-right">
                                                <b-button variant="dark" class="mt-2" @click="modalRegistrarDocumento = true; datosDocumento.idAmbitoDocumento = 5">
                                                    <i class="fas fa-plus fa-sm"></i> Nuevo documento
                                                </b-button>
                                            </b-col>
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina5" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7">

                                            </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter5" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaDocumentos.filter(x => x.idAmbitoDocumento == 5)" :fields="campoDocumentosDireccion" bordered :per-page="porPagina5" :current-page="currentPage5" @filtered="onFiltered5" :filter="filter5" :filter-included-fields="filterOn5" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template #cell(opciones)="param">
                                                        <b-button @click="descargarDocumento(param.item.urlArchivo)" class="mr-1 mb-1" variant="blue" size="sm" v-c-tooltip="'Descargar'">
                                                            <i class="fas fa-download"></i>
                                                        </b-button>
                                                        <b-button v-if="checkPermissions('001-DOC-DES-DIR','u') == 1" @click="abrirModalActualizarDocumento(param, 5)" class="mr-1 mb-1" variant="dark" size="sm" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search"></i>
                                                        </b-button>
                                                        <b-button v-if="checkPermissions('001-DOC-DES-DIR','d') == 1" @click="eliminarDocumento(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                    <template #cell(nombreProceso)="param">
                                                        {{param.item.nombreProceso | truncate(25)}}
                                                    </template>
                                                    <template #cell(nombreTipoDocumentacion)="param">
                                                        {{param.item.nombreTipoDocumentacion | truncate(25)}}
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr>
                                                <b-pagination v-model="currentPage5" :total-rows="totalRows5" :per-page="porPagina5" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                </b-tabs>

                            </b-col>
                        </b-row>

                        <CModal size="lg" color="primario" :show.sync="modalRegistrarDocumento" :closeOnBackdrop="false">
                            <template #header>
                                <h6 class="modal-title">
                                    <i class="fas fa-plus-square fa-md"></i>
                                    <span class="h5"> Nuevo documento </span>
                                </h6>
                                <CButtonClose @click="modalRegistrarDocumento = false" class="text-white" />
                            </template>

                            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(registrarDocumentacion)">
                                    <b-row>
                                        <b-col md="4" v-if="datosDocumento.idAmbitoDocumento != 4">
                                            <validation-provider name="código" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Código:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el código" v-model="datosDocumento.codigo"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Nombre:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosDocumento.nombre"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4" v-if="datosDocumento.idAmbitoDocumento == 1 || datosDocumento.idAmbitoDocumento == 5">
                                            <validation-provider name="revisión" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Revisión:" class="mb-2">
                                                    <b-form-input type="number" step=".01" :state="getValidationState(validationContext)" placeholder="Ingrese revisión" v-model="datosDocumento.revision"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4" v-if="datosDocumento.idAmbitoDocumento == 2 || datosDocumento.idAmbitoDocumento == 3 || datosDocumento.idAmbitoDocumento == 4">
                                            <validation-provider name="ubicación" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Ubicación:" class="mb-2">
                                                    <b-form-select :state="getValidationState(validationContext)" :options="comboUbicacion" value-field="idUbicacion" text-field="descripcion" v-model="datosDocumento.tipoUbicacion">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12" v-if="datosDocumento.tipoUbicacion==2 && datosDocumento.idAmbitoDocumento!=4">
                                            <validation-provider name="especificar ubicación" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Especificar ubicación:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese ubicación" v-model="datosDocumento.ubicacion"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col :md="datosDocumento.idAmbitoDocumento == 2 ? 6 : 4">
                                            <validation-provider name="responsable" rules="required" v-slot="{errors}">
                                                <b-form-group label="Responsable de actualizar:" class="mb-2">
                                                    <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosDocumento.idResponsable , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosDocumento.idResponsable" :options="comboCargoResponsable" @search:blur="blurResponsable">
                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12" v-if="datosDocumento.tipoUbicacion==2 && datosDocumento.idAmbitoDocumento==4">
                                            <validation-provider name="especificar ubicación" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Especificar ubicación:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese ubicación" v-model="datosDocumento.ubicacion"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col :md="datosDocumento.idAmbitoDocumento == 2 ? 6 : 4">
                                            <validation-provider name="tipo" rules="required" v-slot="{errors}">
                                                <b-form-group label="Tipo de información:" class="mb-2">
                                                    <v-select :reduce="comboTipoInformacion =>comboTipoInformacion.idTipoDocumentacion" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosDocumento.idTipoDocumentacion , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosDocumento.idTipoDocumentacion" :options="comboTipoInformacion" @search:blur="blurTipoInformacion">
                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <b-col md="4" v-if="datosDocumento.idAmbitoDocumento == 3">
                                            <validation-provider name="tiempo de conservación" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Tiempo de conservación:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el tiempo de conservación" v-model="datosDocumento.tiempoConservacion"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4" v-if="datosDocumento.idAmbitoDocumento == 1 || datosDocumento.idAmbitoDocumento == 4 || datosDocumento.idAmbitoDocumento == 5 ">
                                            <validation-provider name="fecha de publicación" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Fecha de publicación:" class="mb-2">
                                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosDocumento.fechaPublicacion"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4" v-if="datosDocumento.idAmbitoDocumento == 4">
                                            <validation-provider name="copia" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Copia:" class="mb-2">
                                                    <b-form-select :state="getValidationState(validationContext)" :options="comboCopia" value-field="idCopia" text-field="descripcion" v-model="datosDocumento.copia">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="proceso" rules="required" v-slot="{errors}">
                                                <b-form-group label="Proceso:" class="mb-2">
                                                    <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosDocumento.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosDocumento.idProceso" :options="comboProcesos" @search:blur="blurProceso">
                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider :rules="{required: true}" name="archivo" v-slot="validationContext">
                                                <b-form-group label="Archivo:" class="mb-2">
                                                    <b-form-file ref="file" v-model="datosDocumento.archivoMeta" :state="(getValidationState(validationContext) )" placeholder="Elija un archivo o arrástrelo aquí..." :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUpload"></b-form-file>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col class="my-2 text-right" md="12">
                                            <b-button :disabled="disabledFile" variant="success" class=" mr-2" type="submit">
                                                Guardar
                                            </b-button>
                                            <b-button variant="danger" @click="modalRegistrarDocumento = false">
                                                Cancelar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </validation-observer>
                            <template #footer>
                                <span></span>
                            </template>
                        </CModal>
                        <CModal size="lg" color="primario" :show.sync="modalActualizarDocumento" :closeOnBackdrop="false">
                            <template #header>
                                <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                                    <span class="h5"> Actualizar documento</span></h6>
                                <CButtonClose @click="modalActualizarDocumento = false" class="text-white" />
                            </template>

                            <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(actualizarDocumento)">
                                    <b-row>
                                        <b-col md="4" v-if="datosActualizarDocumento.idAmbitoDocumento != 4">
                                            <validation-provider name="código" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Código:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el código" v-model="datosActualizarDocumento.codigo"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Nombre:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosActualizarDocumento.nombre"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4" v-if="datosActualizarDocumento.idAmbitoDocumento == 1 || datosActualizarDocumento.idAmbitoDocumento == 5">
                                            <validation-provider name="revisión" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Revisión:" class="mb-2">
                                                    <b-form-input type="number" step=".01" :state="getValidationState(validationContext)" placeholder="Ingrese revisión" v-model="datosActualizarDocumento.revision"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4" v-if="datosActualizarDocumento.idAmbitoDocumento == 2 || datosActualizarDocumento.idAmbitoDocumento == 3 || datosActualizarDocumento.idAmbitoDocumento == 4">
                                            <validation-provider name="ubicación" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Ubicación:" class="mb-2">
                                                    <b-form-select :state="getValidationState(validationContext)" :options="comboUbicacion" value-field="idUbicacion" text-field="descripcion" v-model="datosActualizarDocumento.tipoUbicacion">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12" v-if="datosActualizarDocumento.tipoUbicacion==2 && datosActualizarDocumento.idAmbitoDocumento!=4">
                                            <validation-provider name="especificar ubicación" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Especificar ubicación:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese ubicación" v-model="datosActualizarDocumento.ubicacion"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col :md="datosActualizarDocumento.idAmbitoDocumento == 2 ? 6 : 4">
                                            <validation-provider name="responsable" rules="required" v-slot="{errors}">
                                                <b-form-group label="Responsable de actualizar:" class="mb-2">
                                                    <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarDocumento.idResponsable , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarDocumento.idResponsable" :options="comboCargoResponsable" @search:blur="blurActualizarResponsable">
                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12" v-if="datosActualizarDocumento.tipoUbicacion==2 && datosActualizarDocumento.idAmbitoDocumento==4">
                                            <validation-provider name="especificar ubicación" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Especificar ubicación:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese ubicación" v-model="datosActualizarDocumento.ubicacion"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col :md="datosActualizarDocumento.idAmbitoDocumento == 2 ? 6 : 4">
                                            <validation-provider name="tipo" rules="required" v-slot="{errors}">
                                                <b-form-group label="Tipo de información:" class="mb-2">
                                                    <v-select :reduce="comboTipoInformacion =>comboTipoInformacion.idTipoDocumentacion" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarDocumento.idTipoDocumentacion , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarDocumento.idTipoDocumentacion" :options="comboTipoInformacion" @search:blur="blurActualizarTipoInformacion">
                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <b-col md="4" v-if="datosActualizarDocumento.idAmbitoDocumento == 3">
                                            <validation-provider name="tiempo de conservación" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Tiempo de conservación:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el tiempo de conservación" v-model="datosActualizarDocumento.tiempoConservacion"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4" v-if="datosActualizarDocumento.idAmbitoDocumento == 1 || datosActualizarDocumento.idAmbitoDocumento == 4 || datosActualizarDocumento.idAmbitoDocumento == 5 ">
                                            <validation-provider name="fecha de publicación" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Fecha de publicación:" class="mb-2">
                                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosActualizarDocumento.fechaPublicacion"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4" v-if="datosActualizarDocumento.idAmbitoDocumento == 4">
                                            <validation-provider name="copia" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Copia:" class="mb-2">
                                                    <b-form-select :state="getValidationState(validationContext)" :options="comboCopia" value-field="idCopia" text-field="descripcion" v-model="datosActualizarDocumento.copia">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="proceso" rules="required" v-slot="{errors}">
                                                <b-form-group label="Proceso:" class="mb-2">
                                                    <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarDocumento.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarDocumento.idProceso" :options="comboProcesos" @search:blur="blurActualizarProceso">
                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="archivo" v-slot="validationContext">
                                                <b-form-group label="Archivo:" class="mb-2">
                                                    <b-form-file ref="filex" v-model="datosActualizarDocumento.archivoMeta" :state="(getValidationState(validationContext) )" :placeholder="datosActualizarDocumento.nombreArchivo == '' ?  'Elija un archivo o arrástrelo aquí...' : datosActualizarDocumento.nombreArchivo" :accept="accept.toString()" browse-text="Subir" v-on:change="handleActualizarFileUpload"></b-form-file>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col class="my-2 text-right" md="12">
                                            <b-button :disabled="disabledFile" variant="success" class=" mr-2" type="submit">
                                                Guardar
                                            </b-button>
                                            <b-button variant="danger" @click="modalActualizarDocumento = false">
                                                Cancelar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </validation-observer>

                            <template #footer>
                                <span></span>
                            </template>
                        </CModal>
                    </div>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {

    data() {
        return {
            idCliente: '',
            disabledFile: false,
            modalRegistrarDocumento: false,
            modalActualizarDocumento: false,
            comboCargoResponsable: [],

            totalRows1: 1,
            currentPage1: 1,
            filter1: null,
            filterOn1: [],
            porPagina1: 10,

            totalRows2: 1,
            currentPage2: 1,
            filter2: null,
            filterOn2: [],
            porPagina2: 10,

            porPagina3: 10,
            totalRows3: 1,
            currentPage3: 1,
            filter3: null,
            filterOn3: [],
            porPagina3: 10,

            porPagina4: 10,
            totalRows4: 1,
            currentPage4: 1,
            filter4: null,
            filterOn4: [],
            porPagina4: 10,

            porPagina5: 10,
            totalRows5: 1,
            currentPage5: 1,
            filter5: null,
            filterOn5: [],
            porPagina5: 10,

            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            comboTipoInformacion: [],
            comboProcesos: [],
            comboEmpleados: [],
            comboCopia: [{
                idCopia: 1,
                descripcion: 'Original'
            }, {
                idCopia: 2,
                descripcion: 'Controlado'
            }, {
                idCopia: 3,
                descripcion: 'No Controlado'
            }],
            comboUbicacion: [{
                idUbicacion: 1,
                descripcion: 'Virtual'
            }, {
                idUbicacion: 2,
                descripcion: 'Fisica'
            }],
            campoDocumentosInternos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "codigo",
                    label: "Código",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Documento",
                    class: "text-center",
                },
                {
                    key: "revision",
                    label: "Revisión",
                    class: "text-center",
                },
                {
                    key: "nombreTipoDocumentacion",
                    label: "Tipo de información",
                    class: "text-center",
                },
                {
                    key: "responsable",
                    label: "Responsable de actualizar",
                    class: "text-center",
                },
                {
                    key: "fechaPublicacion",
                    label: "Fecha de publicación",
                    class: "text-center",
                },
                {
                    key: "nombreProceso",
                    label: "Proceso asociado",
                    class: "text-center",
                },
                {
                    label: "",
                    key: "opciones",
                    class: "text-center"
                }
            ],
            campoDocumentosExternos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "codigo",
                    label: "Código",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Documento",
                    class: "text-center",
                },
                {
                    key: "nombreTipoDocumentacion",
                    label: "Tipo de información",
                    class: "text-center",
                },
                {
                    key: "responsable",
                    label: "Responsable de actualizar",
                    class: "text-center",
                },
                {
                    key: "tipoUbicacion",
                    label: "Ubicación",
                    class: "text-center",
                },
                {
                    key: "nombreProceso",
                    label: "Proceso asociado",
                    class: "text-center",
                },
                {
                    label: "",
                    key: "opciones",
                    class: "text-center"
                }
            ],
            campoDocumentosRegistros: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "codigo",
                    label: "Código",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Documento",
                    class: "text-center",
                },
                {
                    key: "nombreTipoDocumentacion",
                    label: "Tipo de información",
                    class: "text-center",
                },
                {
                    key: "responsable",
                    label: "Responsable de actualizar",
                    class: "text-center",
                },
                {
                    key: "tipoUbicacion",
                    label: "Ubicación",
                    class: "text-center",
                },

                {
                    key: "tiempoConservacion",
                    label: "Tiempo conservación",
                    class: "text-center",
                },
                {
                    key: "nombreProceso",
                    label: "Proceso asociado",
                    class: "text-center",
                },
                {
                    label: "",
                    key: "opciones",
                    class: "text-center"
                }
            ],
            campoDocumentosDistribucion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "fechaPublicacion",
                    label: "Fecha de publicación",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Documento",
                    class: "text-center",
                },
                {
                    key: "nombreTipoDocumentacion",
                    label: "Tipo de información",
                    class: "text-center",
                },
                {
                    key: "responsable",
                    label: "Responsable de actualizar",
                    class: "text-center",
                },
                {
                    key: "tipoUbicacion",
                    label: "Ubicación",
                    class: "text-center",
                },
                {
                    key: "copia",
                    label: "Copia",
                    class: "text-center",
                },
                {
                    key: "nombreProceso",
                    label: "Proceso asociado",
                    class: "text-center",
                },
                {
                    label: "",
                    key: "opciones",
                    class: "text-center"
                }
            ],
            campoDocumentosDireccion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "codigo",
                    label: "Código",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Documento",
                    class: "text-center",
                },
                {
                    key: "revision",
                    label: "Revisión",
                    class: "text-center",
                },
                {
                    key: "nombreTipoDocumentacion",
                    label: "Tipo de información",
                    class: "text-center",
                },
                {
                    key: "responsable",
                    label: "Responsable de actualizar",
                    class: "text-center",
                },
                {
                    key: "fechaPublicacion",
                    label: "Fecha de publicación",
                    class: "text-center",
                },
                {
                    key: "nombreProceso",
                    label: "Proceso asociado",
                    class: "text-center",
                },
                {
                    label: "",
                    key: "opciones",
                    class: "text-center"
                }
            ],
            listaDocumentos: [],
            datosDocumento: {
                archivoMeta: null,
                idCliente: '',
                codigo: '',
                nombre: '',
                revision: '',
                idAmbitoDocumento: '',
                idTipoDocumentacion: null,
                idResponsable: null,
                tipoUbicacion: null,
                ubicacion: '',
                tiempoConservacion: '',
                copia: null,
                idProceso: null,
                fechaPublicacion: moment().format('YYYY-MM-DD'),
            },
            datosActualizarDocumento: {
                idDocumentacion: '',
                idCliente: '',
                archivoMeta: null,
                nombreArchivo: '',
                urlArchivo: '',
                codigo: '',
                nombre: '',
                revision: '',
                idAmbitoDocumento: '',
                idTipoDocumentacion: null,
                idResponsable: null,
                tipoUbicacion: null,
                ubicacion: '',
                tiempoConservacion: '',
                copia: null,
                idProceso: null,
                fechaPublicacion: '',
            },
            accept: [
                '.vsd', '.vsdx', '.xlsx', '.xls', '.csv',
                '.pdf', '.doc', '.docx', '.ppt', '.pptx',
                '.png', '.jpg', '.jpeg', '.pdf', '.docx',
                'application/msword',
                'application/vnd.ms-excel'
            ]
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
 
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
                this.datosDocumento.archivoMeta = this.$refs.file.files[0];
            }
        },
        handleActualizarFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["filex"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["filex"].reset();
                    return;
                }
                this.datosActualizarDocumento.archivoMeta = this.$refs.filex.files[0];
            }
        },
        onFiltered1(filteredItems) {
            this.totalRows1 = filteredItems.length
            this.currentPage1 = 1
        },
        onFiltered2(filteredItems) {
            this.totalRows2 = filteredItems.length
            this.currentPage2 = 1
        },
        onFiltered3(filteredItems) {
            this.totalRows3 = filteredItems.length
            this.currentPage3 = 1
        },
        onFiltered4(filteredItems) {
            this.totalRows4 = filteredItems.length
            this.currentPage4 = 1
        },
        onFiltered5(filteredItems) {
            this.totalRows5 = filteredItems.length
            this.currentPage5 = 1
        },
        descargarDocumento(url) {
            window.open(url)
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosDocumento.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboCargoResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarDocumentacion() {
            let me = this;
            me.disabledFile = true;

            const formData = new FormData();

            formData.append("file", me.datosDocumento.archivoMeta);
            formData.append("folder", 'documentacion/cargar-descargar-documentos');

            formData.append("idCliente", me.datosDocumento.idCliente);
            formData.append("codigo", me.datosDocumento.codigo);
            formData.append("nombre", me.datosDocumento.nombre);
            formData.append("revision", me.datosDocumento.revision);
            formData.append("idAmbitoDocumento", me.datosDocumento.idAmbitoDocumento);
            formData.append("idTipoDocumentacion", me.datosDocumento.idTipoDocumentacion);
            formData.append("idResponsable", me.datosDocumento.idResponsable);
            formData.append("tipoUbicacion", me.datosDocumento.tipoUbicacion);
            formData.append("ubicacion", me.datosDocumento.ubicacion);
            formData.append("tiempoConservacion", me.datosDocumento.tiempoConservacion);
            formData.append("copia", me.datosDocumento.copia);
            formData.append("idProceso", me.datosDocumento.idProceso);
            formData.append("fechaPublicacion", me.datosDocumento.fechaPublicacion);

             formData.append("nombreArchivo", null);
            formData.append("urlArchivo", null);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-documentacion",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalRegistrarDocumento = false;
                    me.listarDocumentacion();
                    me.disabledFile = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabledFile = false;
                });
        },
        actualizarDocumento() {
            let me = this;
            me.disabledFile = true;

            const formData = new FormData();

            formData.append("file", me.datosActualizarDocumento.archivoMeta);
            formData.append("folder", 'documentacion/cargar-descargar-documentos');

            formData.append("idCliente", me.datosActualizarDocumento.idCliente);
            formData.append("idDocumentacion", me.datosActualizarDocumento.idDocumentacion);
            formData.append("nombreArchivo", me.datosActualizarDocumento.nombreArchivo);
            formData.append("urlArchivo", me.datosActualizarDocumento.urlArchivo);

            formData.append("codigo", me.datosActualizarDocumento.codigo);
            formData.append("nombre", me.datosActualizarDocumento.nombre);
            formData.append("revision", me.datosActualizarDocumento.revision);
            formData.append("idAmbitoDocumento", me.datosActualizarDocumento.idAmbitoDocumento);
            formData.append("idTipoDocumentacion", me.datosActualizarDocumento.idTipoDocumentacion);
            formData.append("idResponsable", me.datosActualizarDocumento.idResponsable);
            formData.append("tipoUbicacion", me.datosActualizarDocumento.tipoUbicacion);
            formData.append("ubicacion", me.datosActualizarDocumento.ubicacion);
            formData.append("tiempoConservacion", me.datosActualizarDocumento.tiempoConservacion);
            formData.append("copia", me.datosActualizarDocumento.copia);
            formData.append("idProceso", me.datosActualizarDocumento.idProceso);
            formData.append("fechaPublicacion", me.datosActualizarDocumento.fechaPublicacion);


            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-documentacion",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalActualizarDocumento = false;
                    me.listarDocumentacion();
                    me.disabledFile = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabledFile = false;
                });
        },
        eliminarDocumento(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el documento?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-documentacion", {
                                idDocumentacion: param.item.idDocumentacion
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarDocumentacion();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        listarDocumentacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-documentacion", {
                        params: {
                            idCliente: me.datosDocumento.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaDocumentos = response.data
                    me.totalRows1 = me.listaDocumentos.filter(x => x.idAmbitoDocumento == 1).length
                    me.totalRows2 = me.listaDocumentos.filter(x => x.idAmbitoDocumento == 2).length
                    me.totalRows3 = me.listaDocumentos.filter(x => x.idAmbitoDocumento == 3).length
                    me.totalRows4 = me.listaDocumentos.filter(x => x.idAmbitoDocumento == 4).length
                    me.totalRows5 = me.listaDocumentos.filter(x => x.idAmbitoDocumento == 5).length
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarProcesos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-procesos", {
                        params: {
                            idCliente: me.datosDocumento.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboProcesos = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        listarTipoDocumentacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-tipo-documentacion", {
                        params: {
                            idCliente: me.datosDocumento.idCliente
                        }
                    })
                .then(function (response) {
                    me.comboTipoInformacion = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        /* listarEmpleados() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-empleados", {
                        params: {
                            idCliente: me.datosDocumento.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboEmpleados = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        }, */

        blurTipoInformacion() {
            this.computedForm.refs.tipo.validate();
        },
        blurProceso() {
            this.computedForm.refs.proceso.validate();
        },
        blurResponsable() {
            this.computedForm.refs.responsable.validate();
        },

        blurActualizarTipoInformacion() {
            this.computedActualizarForm.refs.tipo.validate();
        },
        blurActualizarProceso() {
            this.computedActualizarForm.refs.proceso.validate();
        },
        blurActualizarResponsable() {
            this.computedActualizarForm.refs.responsable.validate();
        },

        abrirModalActualizarDocumento(param, idAmbito) {
            let me = this;

            me.datosActualizarDocumento.idAmbitoDocumento = idAmbito;
            me.datosActualizarDocumento.idCliente = param.item.idCliente;
            me.datosActualizarDocumento.idDocumentacion = param.item.idDocumentacion;

            me.datosActualizarDocumento.nombreArchivo = param.item.nombreArchivo;
            me.datosActualizarDocumento.urlArchivo = param.item.urlArchivo;

            me.datosActualizarDocumento.codigo = param.item.codigo;
            me.datosActualizarDocumento.nombre = param.item.nombre;
            me.datosActualizarDocumento.revision = param.item.revision;
            me.datosActualizarDocumento.idTipoDocumentacion = param.item.idTipoDocumentacion;
            me.datosActualizarDocumento.idResponsable = param.item.idResponsable;
            me.datosActualizarDocumento.tipoUbicacion = param.item.tipoUbicacion;
            me.datosActualizarDocumento.ubicacion = param.item.ubicacion;
            me.datosActualizarDocumento.tiempoConservacion = param.item.tiempoConservacion;
            me.datosActualizarDocumento.copia = param.item.copia;
            me.datosActualizarDocumento.idProceso = param.item.idProceso;
            me.datosActualizarDocumento.fechaPublicacion = param.item.fechaPublicacion;
            me.modalActualizarDocumento = true;
        },
        resetModalRegistrarDocumento() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosDocumento.archivoMeta = null;
            this.datosDocumento.codigo = '';
            this.datosDocumento.nombre = '';
            this.datosDocumento.revision = '';
            this.datosDocumento.idTipoDocumentacion = null;
            this.datosDocumento.idResponsable = null;
            this.datosDocumento.tipoUbicacion = null;
            this.datosDocumento.ubicacion = '';
            this.datosDocumento.tiempoConservacion = '';
            this.datosDocumento.copia = null;
            this.datosDocumento.idProceso = null;
            this.datosDocumento.fechaPublicacion = moment().format('YYYY-MM-DD');

        },

        resetModalActualizarDocumento() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        }
    },
    watch: {
        modalRegistrarDocumento: function (val) {
            if (val == false) {
                this.resetModalRegistrarDocumento();
            }
        },
        modalActualizarDocumento: function (val) {
            if (val == false) {
                this.resetModalActualizarDocumento();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosDocumento.idCliente = this.datosActualizarDocumento.idCliente = user.uidClient;
            this.listarDocumentacion();
            this.listarProcesos();
            this.listarTipoDocumentacion();
            //this.listarEmpleados();
            this.listarPuestosTrabajo();
        }
    }

}
</script>
